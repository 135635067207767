import $ from 'jquery';
import 'bootstrap';
$(document).ready(function() {
	$('.openPopup').click(function() {
		$('#popupContainer').addClass('open');
	});

	$('#closePopup').click(function() {
		$('#popupContainer').removeClass('open');
	});

	var maxHeight = 0;
	$(".single-realisation > a > figure").each(function(){
		if ($(this).height() > maxHeight) { maxHeight = $(this).height(); }
	});
	$(".single-realisation > a > figure").height(maxHeight);

});
